import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from '@filou/core';
import createRenderer from './renderer';

// Your top level component
import App from './app';

// Export your top level component as JSX (for static rendering)
export default App;

// Render your app
if (typeof document !== 'undefined') {
  const renderMethod = module['hot']
    ? ReactDOM.render
    : ReactDOM.hydrate || ReactDOM.render;
  const render = (Comp: React.ComponentType) => {
    const renderer = createRenderer();
    renderMethod(
      <AppContainer>
        <Provider renderer={renderer}>
          <Comp />
        </Provider>
      </AppContainer>,
      document.getElementById('root')
    );
  };

  // Render!
  render(App);
  // Hot Module Replacement
  if (module['hot']) {
    module['hot'].accept('./app', () => render(require('./app').default));
  }
}
