import { createRenderer } from '@filou/core';

export default () => {
  const renderer = createRenderer();
  renderer.renderFont(
    'Quicksand',
    ['https://fonts.gstatic.com/s/quicksand/v8/6xKtdSZaM9iE8KbpRA_hK1QN.woff2'],
    { weight: 400 }
  );
  renderer.renderStatic(`
body {
  font-family: 'Quicksand', sans-serif;
  font-weight: 300;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10px, 0, 0);
            transform: translate3d(10px, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
          animation-name: fadeInUp;
}
`);
  return renderer;
};
