import * as React from 'react';
import { Router } from 'react-static';
import { ThemeProvider } from '@filou/core';
import Routes from 'react-static-routes';

const theme = {
  fontFamily: '"Quicksand", sans-serif',
  color: '#215E8B',
  borderRadius: 0
};
const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ThemeProvider>
);

export default App;
